<template>
  <div>
    <v-card>
      <v-card-title>Profil</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" xs="12" sm="8" md="6" lg="4" xl="3">
            <v-form ref="changePasswordForm" v-model="isChangePasswordValid">
              <v-text-field
                flat
                rounded
                outlined
                label="Parola veche"
                type="password"
                prepend-inner-icon="mdi-lock"
                @focus="showError = false"
                v-model="oldPassword"
                :rules="simpleFieldValidation"
              />
              <v-text-field
                flat
                rounded
                outlined
                label="Parola noua"
                type="password"
                prepend-inner-icon="mdi-lock"
                @focus="showError = false"
                v-model="newPassword"
                :rules="simpleFieldValidation"
              />
              <v-text-field
                flat
                rounded
                outlined
                label="Confirma parola noua"
                type="password"
                prepend-inner-icon="mdi-lock"
                @focus="showError = false"
                v-model="confirmNewPassword"
                :rules="samePassword"
              />
            </v-form>
            <v-alert v-if="showError" border="top" colored-border type="error"
              >Parola veche nu coincide</v-alert
            >
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="2"
            ><v-btn
              color="secondary"
              block
              depressed
              rounded
              :loading="changePasswordLoading"
              @click="confirmChangePassword"
            >
              Schimba parola
            </v-btn></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Results",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showError: false,
      isChangePasswordValid: false,
      changePasswordLoading: false,
      simpleFieldValidation: [(value) => !!value || "Camp obligatoriu"],
      samePassword: [
        (value) =>
          (!!value && this.newPassword == value) || "Parolele nu coincid",
      ],
    };
  },
  async created() {},
  computed: {
    ...mapState("authentication", {
      auth_token: "auth_token",
      user: "user",
    }),
  },
  methods: {
    async confirmChangePassword() {
      this.$refs.changePasswordForm.validate();
      if (!this.isChangePasswordValid) {
        return;
      }

      this.changePasswordLoading = true;
      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/users/changePassword`,
          {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.auth_token}`,
            },
          }
        );
      } catch (_) {
        this.showError = true;
        console.log(_);
      } finally {
        this.changePasswordLoading = false;
      }
    },
  },
};
</script>